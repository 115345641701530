import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, image, article }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)
  
  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }
  
  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      
      <link rel="dns-prefetch preconnect" href="https://cdn.jsdelivr.net" crossorigin="anonymous" />
      <link rel="dns-prefetch preconnect" href="https://stats.petoffice.eu" crossorigin="anonymous" />
      <link rel="shortcut icon" type="image/png" href="https://www.petoffice.eu/images/favicon.png" />
      <link rel="apple-touch-icon" href="https://www.petoffice.eu/images/favicon.png" />
      
      <meta name="author" content="petoffice" />
      <meta name="Revisit-after" content="14 days" />
      <meta content="de" http-equiv="Content-Language" />
      <meta content="0" http-equiv="expires" />
      <meta content="Kommerzielle Organisation" name="page-type" />
      <meta content="Alle" name="audience" />
      <meta name="abstract" content="petoffice | Software für den Tierschutz und die Tiervermittlung" />
      <meta name="keywords" content="tierschutz software, tierschutzsoftware, tiervermittlung, vermittlungssoftware, §11 tierschg software, tierheim software, tier management, tier verwaltung" />
      <meta name="canonical" content="https://www.petoffice.eu/" />
      <meta property="og:site_name" content="petoffice" />
      <meta itemprop="name" content="petoffice - Software für den Tierschutz" />
      <meta itemprop="description" content="petoffice ist die Komplettlösung für die digitale Tiervermittlung. §11 TierSchutzG-konform inklusive Vermittlungsübersicht, Vertragserstellung und Bewerbungsformular" />

      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      <meta property="og:image" content={seo.image || 'https://www.petoffice.eu/images/petoffice-homepage.png'} />
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      <meta name="twitter:image" content={seo.image || 'https://www.petoffice.eu/images/petoffice-homepage.png'} />
    </Helmet>
  )
}

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: 'petoffice ist die Komplettlösung für die digitale Tiervermittlung. §11 TierSchutzG-konform inklusive Vermittlungsübersicht, Vertragserstellung und Bewerbungsformular',
  image: null,
  article: false,
}

